import React from "react"
import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import OurServicesContent from "../components/our-services-content"



const OurServicesPage = () => {
  const {t} = useI18next();
  return (
    <Layout>
      <SEO title={t("MENU.OUR-SERVICES")} />
      <OurServicesContent/>
    </Layout>
  )
} 


export default OurServicesPage