import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {Link, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';

import Scrollspy from 'react-scrollspy';
import scrollToElement from 'scroll-to-element';
import { Link as ScrollToLink } from 'react-scroll'

import styled from "styled-components";

import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';


const Container = styled.div`
    width: 100%;
    // padding-top: 60px;
    text-align: center;
    // background: #ACD433;
    // background: #2c466b;
    // color: wheat;
    // margin-top: 15px;
    margin: 0 auto;
    
    @media screen and (max-width: 1220px) {
        margin-top: 0;
        padding-top: 0;
    }
`

const Section = styled.div`
    
`
const ArticlesContainer = styled.div`
    display: flex;
    
`

const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    
        
    @media screen and (max-width: 1220px) {
        padding-top: 90px;
    }
    
    @media screen and (max-width: 767px) {
        padding-top: 73px;
    }
`

const ContentInner = styled.div`
      width: 73.306%;
    margin: 0 auto;
    // float: left;
  
    margin-left: 0;
    margin-right: 0;
    background: white;
    padding: 32px;
    
    @media screen and (max-width: 768px) {
        width: 95%;
        padding: 8px;
        
        img {
            width: 100%;
        }
    }
    
    li {
        margin-left: 48px;
    }
    
    img {
        display: block;
        margin: auto;
    }
    
    #cb-call-to-action {
        visibility: visible;
        height: 162px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-align: center;
        padding: 5px;
    }
`

const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    width: 90%;
    max-width: 90%;
    max-width: 1372px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    
    p { 
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
        
        // font-size: 20px;
        // line-height: 1.4;
        // margin-top: 14px;
        // font-weight: 400;
        // white-space: pre-wrap;
        // margin-bottom: 14px;
        // text-align: left;
    }
    
    h3 {
        @media screen and (max-width: 767px) {
            font-size: 22px;
        }
    }

    li {
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
`

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
`

const Titles = styled.p`
    font-size: 16px;
    text-align: center;
`

const SideBar = styled.div`
    float: right;
    width: 25%;
    
    @media screen and (max-width: 767px) {
        display: none;
    }

`

const TableOfContent = styled.div`
    position: sticky;
    top: 106px;
    // margin-top: 16px;
    background: rgb(44,70,107);
    padding: 32px;
    
    li {
        list-style-type: none;
        font-size: 18px;
        margin-bottom: 8px;
        cursor: pointer;
        
        a {
            color: white;
        }
        
        &:hover {
            a {
                color: #ff8000;
            }
        }
        
        &.chapter {
            font-weight: 700;
            
            &:hover {
                a {
                    -webkit-text-stroke: 0px;
                    -webkit-text-stroke-width: 0;
                }
            }
        }
        
        &.sub-chapter {
            margin-left: 16px;
            font-size: 16px;
            // list-style: inside;
        }
        
        &.is-current {
            a {
                color: rgb(172, 212, 51);
            }
        }
    }
`



const OurServicesContent = () => {
    
    const context = React.useContext(I18nextContext);
    
    const {t} = useI18next();
    const language = context.language;
    
    const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(our-services)/"  }}) {
          edges {
            node {
              frontmatter {
                language
              }
              html
              id
            }
          }
        }
      }
    `)
    
    function handleSetActive() {
        
    }
    
    let content = data.allMarkdownRemark.edges
    let contentFilteredByLanguage = ""
    
   
        contentFilteredByLanguage = content.filter(item=>item.node.frontmatter.language == language)
    if (language)
    contentFilteredByLanguage = contentFilteredByLanguage[0].node
  
    return (
        <Container>
            <ContentWrapper >
                <SectionHeader title={t("OUR-SERVICES.TITLE")} subtitle={t("OUR-SERVICES.SUBTITLE")}  withBackground={false}/>
                <Content>
                    <SideBar>
                        <TableOfContent>
                             { language == 'en' && 
                             
                                <React.Fragment> 
                                    <Scrollspy 
                                        items={ [
                                            'workshops',
                                            'proprietary-tools',
                                            'asymmetric-influencing-model',
                                            'cognitive-template',
                                            'behavioural-type-enterprise-analysis',
                                            'non-traditional-segmentation-method',
                                            'full-engagement-model',
                                            'background',
                                            'knowledge-workshop',
                                            'challenge-definition',
                                            'resolution-program',
                                            'sustainability-advisory',
                                            'keynote-speeches',
                                            'cb-call-to-action',
                                        ] } 
                                        currentClassName="is-current"
                                        offset={90}
                                    >
                                    <li className="chapter">
                                        <ScrollToLink to="workshops" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Targeted workshops
                                        </ScrollToLink>
                                    </li>    
                                    <li className="chapter">
                                        <ScrollToLink to="proprietary-tools" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Proprietary Tools
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="asymmetric-influencing-model" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Asymmetric Influencing Model™
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="cognitive-template" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Cognitive Template for Strategic Decision Making Optimization
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="behavioural-type-enterprise-analysis" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Behavioural Type Enterprise Analysis™
                                        </ScrollToLink>
                                    </li>
                                     <li className="sub-chapter">
                                        <ScrollToLink to="non-traditional-segmentation-method" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Non-Traditional Segmentation Method
                                        </ScrollToLink>
                                    </li>
                                    <li className="chapter">
                                        <ScrollToLink to="full-engagement-model" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Full engagement model
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="background" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Background
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                         <ScrollToLink to="knowledge-workshop" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            STEP 1 - Knowledge Workshop
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                         <ScrollToLink to="challenge-definition" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            STEP 2 - Challenge Definition
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="resolution-program" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            STEP 3 - Resolution Program
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="sustainability-advisory" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            STEP 4 - Sustainability Advisory
                                        </ScrollToLink>
                                    </li>
                                    <li className="chapter">
                                        <ScrollToLink to="keynote-speeches" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Keynote Speeches
                                        </ScrollToLink>
                                    </li>
                                    </Scrollspy>
                                </React.Fragment>
                                
                            }
                            { language == 'hu' && 
                                <React.Fragment> 
                                    <Scrollspy 
                                        items={ [
                                            'workshopok',
                                            'codebreakers-eszkoztar',
                                            'aszimmetrikus-hatasgyakorlasi-modell',
                                            'kognitiv-elemzesi-eszkoz',
                                            'vallalati-viselkedestipus-elemzese',
                                            'nem-hagyomanyos-szegmentalas',
                                            'teljeskoru-tanacsadas',
                                            'hatter',
                                            'alapozo-workshop',
                                            'feladatdefinicio',
                                            'megvalositas',
                                            'rogzites',
                                            'eloadasok',
                                            'cb-call-to-action',
                                        ] } 
                                        currentClassName="is-current"
                                        offset={90}
                                    >
                                    <li className="chapter">
                                        <ScrollToLink to="workshopok" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Workshopok
                                        </ScrollToLink>
                                    </li>    
                                    <li className="chapter">
                                        <ScrollToLink to="codebreakers-eszkoztar" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            CodeBreakers eszköztár
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="aszimmetrikus-hatasgyakorlasi-modell" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Aszimmetrikus hatásgyakorlási modell™
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="kognitiv-elemzesi-eszkoz" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Döntéshozatali folyamat fejlesztésére alkalmazott kognitív elemzési eszköz
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="vallalati-viselkedestipus-elemzese" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                        Vállalati viselkedéstípus elemzése™
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="nem-hagyomanyos-szegmentalas" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Nem hagyományos szegmentálás
                                        </ScrollToLink>
                                    </li>
                                    <li className="chapter">
                                        <ScrollToLink to="teljeskoru-tanacsadas" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Teljeskörű tanácsadás
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="hatter" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Háttér
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="alapozo-workshop" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            1. Lépés - Alapozó workshop
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="feladatdefinicio" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            2. Lépés - Feladatdefiníció
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="megvalositas" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            3. Lépés - Megvalósítás
                                        </ScrollToLink>
                                    </li>
                                    <li className="sub-chapter">
                                        <ScrollToLink to="rogzites" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            4. Lépés - Rögzítés
                                        </ScrollToLink>
                                    </li>
                                    <li className="chapter">
                                        <ScrollToLink to="eloadasok" smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                                            Előadások
                                        </ScrollToLink>
                                    </li>
                                    </Scrollspy>
                                </React.Fragment>
                            }

                        </TableOfContent>
                    </SideBar>
                        <ContentInner>
                            <div dangerouslySetInnerHTML={{__html: contentFilteredByLanguage.html}} />
                            <div id="cb-call-to-action"><h3><Link to="/contact">{t("OUR-SERVICES.FOR-MORE-DETAILS")}</Link></h3></div>
                        </ContentInner> 
                 
                    
                    
                </Content>
            </ContentWrapper>
        </Container>
    )
}

export default OurServicesContent